body {
  font-size: 0.875rem;
}
.cursor-pointer {
  cursor: pointer;
}

/**********
*** COLORS ***
************/

.btn-success {
  color: #fff;
}
.btn-danger {
  color: #fff;
}

/**********
*** JSON pretty ***
************/
.__json-pretty__ {
  background: none !important;
  color: #4f5d73 !important;
  span {
    background: none;
  }
}

/************
MUI addong
*************/
.mui-form-start-icon {
  font-size: 1.2rem;
  display: block;
  flex-direction: column;
  width: 30px;
  padding-top: 15px;
}
/****
Sidebar
****/
.sidebar-brand {
  &:hover {
    color: #fff;
  }
}
.sidebar-nav .nav-link {
  padding-left: 20px;
}

/****
Multiselect
****/
.multiselect-container {
  &.form-control {
    padding: 0px !important;
  }
  .search-wrapper {
    border: none;
    .searchBox {
      width: 100%;
    }
  }
}

/****
Tabs
****/

.tab-content {
  border-left: 1px solid #c4c9d0;
  border-right: 1px solid #c4c9d0;
  border-bottom: 1px solid #c4c9d0;
  padding: 10px;
  background-color: white;
}

.nav-tabs {
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  color: #768192;
}

.nav-tabs .nav-link i {
  padding-right: 10px;
}

/********
Borders
*********/

.border-dashed {
  border-style: dashed !important;
}

/******** Tables *********/
.table-fixed-head {
  overflow: auto;
  height: 200px;
}
.table-fixed-head thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

/********** STATS *************/

.stats-table,
.stats-table th,
.stats-table td {
  font-size: 0.8rem;
  padding: 0px;
}
.stats-table {
  thead,
  thead th {
    font-weight: bold;
  }
  width: inherit;
  .stats-table-row-total {
    font-weight: bold;
  }
  .stats-table-cell-th,
  .stats-table-cell-td {
    width: 68px;
  }
  .stats-table-cell {
    width: 68px;
    padding: 1px 2px;
  }
  .stats-table-cell-label {
    width: 100%;
    min-width: 300px;
  }
  .stats-table-cell-label {
    width: 150px;
  }
  .stats-table-cell-total {
    font-weight: bold;
  }
}

/********** Tooltip ***********/
.tooltip {
  .tooltip-inner {
    max-width: 350px;
    background-color: $secondary-dark !important;
    color: #fff;
    table,
    tr,
    td {
      background-color: $secondary-dark !important;
      color: #fff;
    }
  }
}

/********** Confirm alert ***********/
.react-confirm-alert-overlay {
  z-index: 9999999;
}
/********** FORMS  ***********/

//on remplace le style de coreui
html:not([dir='rtl']) .form-select {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
html:not([dir='rtl']) legend {
  float: none;
}
.form-switch.large-switch {
  .form-check-input {
    width: 3em;
    height: 1.5em;
  }
}

/********** Html editor  ***********/

.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}

.simple-image {
  .simple-image__caption {
    margin-top: 10px;
    font-size: 0.8rem;
    border: 1px solid #c4c9d0;
    padding: 3px;
  }

  &.withBorder {
    border: 1px solid #e8e8eb;
    padding: 1px;
  }
  &.withBackground {
    background: #eff2f5;
    padding: 10px;
    img {
      display: block;
      max-width: 60%;
      margin: 0 auto;
    }
  }
  &.stretched {
    img {
      width: 100%;
      max-width: none;
    }
  }
}

//MUI
.MuiFormControl-root .MuiInputBase-root {
  background-color: #fff !important;
}

#email-html-editor iframe {
  height: 600px !important;
}

/**********
*** ADS  ***
************/
.ads-campaigns-dropdown {
  .dropdown-toggle {
    text-decoration: none !important;
    &::after {
      display: none !important;
    }
  }
}
.ads-row {
  height: 41px;
}

.ads-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  padding-left: 2px !important;
  padding-right: 2px !important;

  .text-muted {
    font-size: 0.8rem;
  }
  .ads-cell-options {
    display: none;
    font-size: 0.8rem;
  }
  &:hover {
    .ads-cell-options {
      display: block;
    }
  }
}
#campaigns-editor-modal {
  padding-left: 40px;
}

.campaigns-editor {
  display: flex;
  height: 100%;

  .campaigns-editor-sidebar {
    flex: 1 1 auto;
    height: 100%;
    max-width: 350px;
    width: 350px;
    overflow-y: auto;
  }
  .campaigns-editor-content {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    width: 990px;
    z-index: 10;

    .MuiInputBase-root,
    input {
      font-size: 0.9rem;
    }
  }
}
.campaigns-editor-sidebar-item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.85rem;
  i {
    color: #000;
  }
  .campaigns-editor-sidebar-item-label {
    height: 18px;
    overflow: hidden;
  }
  button {
    padding: 0px;
    margin: 0px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  i {
    padding-right: 5px;
  }
  &.active {
    color: #fff;
  }
}

/**********
*** TINYMCE  ***
************/
.tox-tinymce {
  border: 1px solid #e3e3e3 !important;
  border-radius: 0px !important;
}

/**********
*** Unlayer
***/
#editor-1 iframe {
  min-width: 100% !important;
}
